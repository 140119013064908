.content {
    overflow-x: scroll;
}

.content::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.52);
    border-radius: 10px;
    background-color: rgba(33, 33, 33, 0.45);
}

.content::-webkit-scrollbar
{
    width: 12px;
    height: 10px;
    background-color: initial;
}

.content::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(255, 253, 253, 0.32);
}

.prefix {
    color: #D8DB47;
}