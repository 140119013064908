.inputGroup {
   /* margin: 3vh 0 6vh;*/
    /*height: 6.5vh;*/
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;

}

.inputGroup .input:focus {
    border: 1px solid var(--black) !important;
}

.inputGroup.hasError .input{
    border-color: var(--red) !important;
}


.inputGroup label {
    direction: ltr;
    font-size: 1.2rem;
    padding: 1vh 0vw 1vh 0vw;
    z-index: 1;
}
.inputGroup .after  {
    position: absolute;
    bottom: 0;
    width: 15%;
    height: 6.5vh;
    background-color: var(--darkBlue);
    border: 0.8px solid var(--darkGray);
    border-radius: 0 8px 8px 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 480px) {

    .inputGroup .after  {
        width: 45%;
    }

}



.thisIcon{
    position: absolute;
    left: 2.5%;
}


.inputGroup .input {
    width: 100%;
    height: 6.5vh;
    border: 0.8px solid var(--darkGray);
    border-radius: 8px;
    font-style: normal;
    font-family: "Comfortaa", sans-serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    text-align: start;
    padding: 0vh 2vw;
    direction: ltr;
    display: block;
    font-size: 1.25rem;
    background-color: var(--darkBlue);
    color: var(--white);
}

.inputGroup textarea {
    width: 100%;
    padding: 1.2vh 2.5vw;

    box-sizing: border-box;
    resize: none;
    /*min-height: 6vh;*/
    border: 0.8px solid #E0E0E0;
    border-radius: 100px;
    font-style: normal;
    font-family: iranyekan, serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    text-align: start;
    direction: rtl;
    display: block;
}
.inputGroup.show-error .input:focus {
    border-color: var(--red) !important;
}
.inputGroup.show-error .input:focus + label {
    color: var(--red);
}
.inputGroup.show-error label {
    color: var(--red);
}
.inputGroup.show-error .input {
    border-color: var(--red);
}
.inputGroup.show-error .error-box img, .inputGroup.show-error .error-box p {
    display: block;
}
.inputGroup.show-error.password-input .pass-icon {
    display: none;
}
.inputGroup .error-box img {
    width: 5vw;
    top: 3.68vh;
    left: 15.25vw;
    display: none;
    position: absolute;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup .error-box p {
    display: none;
    text-align: right;
    padding: 0.5vh 15vw;
    margin-bottom: -0.75vh;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup .input:focus + label {
    /*left: 3.5vw;
    top: 0.2vh;
    font-size: 1.25vw;
    z-index: 1;*/
    /*left: 8vw;*/
    top: -30%;
    font-size: 0.9rem;
    z-index: 1;
    transition: top ease 5s;
}
.inputGroup .hasValue {
    /*left: 8vw !important;*/
    /*left: 3.5vw !important;
    top: 0.2vh !important;
    font-size: 1.25vw !important;*/
}


.select {

}

.icon {
    position: absolute;
    left: 3.5%;
    z-index: 2;
}


.inputGroup :global(.rmdp-container ) {
    width: 100%;
    height: 100%;

}

.inputGroup :global(.b-date ) {
    font-family: iranyekan, serif !important;
}
:global(.ltr ) .inputGroup :global(.b-date ) {
    font-family: 'FractulAlt','Mulish', sans-serif !important;
}

/*.inputGroup :global(.rmdp-wrapper ) {

    width: 95% !important;
}
@media (max-width: 480px) {
    .inputGroup :global(.rmdp-wrapper ) {
        width: max-content !important;
    }
}*/



.inputGroup :global(.bg-dark.rmdp-wrapper) {
    background-color: var(--cardBody) !important;
    color: var(--textColor) !important;
}

.inputGroup :global(.rmdp-shadow ) {
    box-shadow: 0 0 5px var(--cardHeader);
    border: 1.5px solid var(--cardBorder);
}
.inputGroup :global(.rmdp-ep-shadow:after ) {
    box-shadow: 0 0 5px var(--cardHeader);
    border: 1.5px solid var(--cardBorder);
}

.inputGroup :global(.rmdp-panel-body li .b-date) {
    padding: 0;
}
.inputGroup :global(.rmdp-panel-body li) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    padding: 1vh 2.5vw;
    margin: 1.25vh 0.3vw;
}

.inputGroup :global(.rmdp-container input ) {
    /*border: none;*/
    text-align: center;
    background: none;
    color: var(--textColor) !important;
}

.datePicker {
    width: 100%;
    height: 100%;
    direction: rtl !important;
}


.selectOptions {
    padding: 1vh 1.5vw !important;
}

:global .select__control {
    border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

:global .select__menu {
    margin-top: 0;
}

:global .select__indicator-separator {
    width: 2px !important;
    webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    background: var(--darkBlue) !important;
    border: none !important;
    height: auto !important;
}

:global .select__menu {
    background-color: var(--darkGray) !important;
}

:global .select__single-value {
    color: var(--white) !important;
}

:global .select__value-container {
    justify-content: center;
}

.isFocused {
    background-color: var(--orange) !important;
    color: #000 !important;
}

.isSelected {
    background-color: var(--activeTab) !important;
    /*background-color: var(--cardHeaderAlpha) !important;*/
    color: #fff !important;
}

:global .select__value-container {
    justify-content: center;
}

.selectBox {
    width: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.menuList::-webkit-scrollbar {
    background: transparent !important;
    width: 0.55vw !important;
}

.menuList::-webkit-scrollbar-thumb {
    background: var(--scrollBar) !important;
    border-radius: 100px;
    /*width: 1px !important;*/
}
